import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from 'gatsby';
import './colophon.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="colophon">
        <div className="colophon__inner">
            <Link to="/#colophon-link" className="colophon__home-link">
                <svg className="colophon__home-link-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6663 9.16732H6.52467L11.183 4.50898L9.99967 3.33398L3.33301 10.0007L9.99967 16.6673L11.1747 15.4923L6.52467 10.834H16.6663V9.16732Z" />
                </svg>
                <span className="colophon__home-text body">Tin Yeah No</span>
            </Link>
            {children}
        </div>
    </div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Colophon`}</h1>
    <p>{`Jeremy Tinianow designed and developed this site. “Tin Yeah No” is how you pronounce his last name.`}</p>
    <p>{`This site was designed in `}<a parentName="p" {...{
        "href": "https://www.figma.com/"
      }}>{`Figma`}</a>{` and built with `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{`. It's hosted on `}<a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{`.`}</p>
    <p>{`The big beautiful serif display face is called `}<a parentName="p" {...{
        "href": "https://thenorthernblock.co.uk/font/moret"
      }}>{`Moret`}</a>{` and was designed by `}<a parentName="p" {...{
        "href": "https://thenorthernblock.co.uk/blog/meet-the-team-jamie-chang"
      }}>{`Jamie Chang`}</a>{`. Most of the type is set in `}<a parentName="p" {...{
        "href": "https://klim.co.nz/retail-fonts/national-2/"
      }}>{`National 2`}</a>{` from `}<a parentName="p" {...{
        "href": "https://klim.co.nz/"
      }}>{`Klim Type Foundry`}</a>{`. The little monospaced bits use `}<a parentName="p" {...{
        "href": "https://fontsarena.com/etc-trispace-by-etc/"
      }}>{`ETC Trispace`}</a>{` from `}<a parentName="p" {...{
        "href": "https://www.etc.supply/"
      }}>{`Etcetera Type Company`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      